import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs ,doc , setDoc} from 'firebase/firestore'
import { utilService } from './util.service'

const firebaseConfig = {
  apiKey: "AIzaSyCoome1UAqzRbmuPkWzx9SukDqLxcSnbJk",
  authDomain: "portfolio-ee398.firebaseapp.com",
  projectId: "portfolio-ee398",
  storageBucket: "portfolio-ee398.appspot.com",
  messagingSenderId: "754374068402",
  appId: "1:754374068402:web:ee5e37725962e818e2a1ab",
  measurementId: "G-450LQ19L5M"
}

export default {
  get,
  add
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

async function add(review){
  review.id = utilService.makeId()
  return await setDoc(doc(db,'review',review.id), review)
}

async function get() {
  const reviewCol = collection(db, 'review')
  const reviewSnapshot = await getDocs(reviewCol)
  const reviewList = reviewSnapshot.docs.map(doc => doc.data())
  return reviewList
}